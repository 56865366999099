@import 'styles/variables.module.scss';

.subscription-main-container {
  padding: 20px 0px;
  font-family: 'Roboto';
  margin: auto;
  width: 760px;
}
.wrap-input {
  width: 100%;
}

.header {
  font-size: 24px;
  color: #b10f15;
  margin-bottom: 16px;
  font-weight: 500;
}

.review {
  padding: 16px 20px;
  background-color: #f5faff;
  border: solid 1px #afcbea;
  border-radius: 10px;
  position: relative;
}

.wrap-benefit-and-img {
  display: flex;
}

.benefit-container {
  width: 359px;
}
.image-container {
  width: 375px;
  text-align: end;
  margin-top: -44px;
  margin-bottom: 24px;
}

.offer-container {
  width: 359px;
}

.offer-text {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
}

.wrap-change-plane {
  display: flex;
  align-items: flex-end;
}
.wrap-change-plane-free {
  display: flex;
}
.wrap-change-plane-left {
  text-align: center;
  width: 449px;
  height: 106px;
}
.wrap-change-plane-right {
  text-align: center;
  width: 285px;
}
.steps {
  font-size: 22px;
  opacity: 0.6;
  margin-bottom: 18px;
}

.main-header {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.header-description {
  font-size: 14px;
}

.benefit-text {
  color: #15213d;
  font-size: 14px;
  line-height: 1.71;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;

  img {
    width: 12px;
    margin-right: 15px;
  }
}

.cover-photo {
  max-height: 136px;
  max-width: 136px;
}

.offer-img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 11.5px;
}

.message {
  display: flex;
  font-size: 13px;
  color: #15213d;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.remove {
  cursor: pointer;
}

.small-text {
  font-size: 10px;
  color: #727272;
  margin-top: 10px;
  margin-bottom: 0px;
}

.checkbox-mobile {
  display: block;
  width: 449px;
}

.checkbox {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;

  :global(.ant-checkbox-checked) {
    :global(.ant-checkbox-inner) {
      border-color: white !important;
      background-color: #15213d;
    }
  }

  :global(.ant-checkbox-wrapper) {
    display: flex !important;

    :global(.ant-checkbox) {
      margin-top: 6px;
    }

    :global(.ant-checkbox-inner) {
      border-color: #15213d !important;
    }

    :global(.ant-checkbox-checked::after) {
      border: none;
    }
  }
}

.offer-card {
  margin: 0 0 10px;
  padding: 18.5px 10.1px;
  font-size: 13px;
  display: flex;
  border-radius: 7px;
  border: solid 1px #d3d3d3;
  background-color: #fff;
}

.pay {
  width: 200px;
  height: 46px;
  border-radius: 8px;
  background-color: #b10f15;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.disabled {
  background-color: #d3d3d3;
}
.sub-text {
  font-size: 14px;
  color: #15213d;
  white-space: nowrap;
}

.total-amount {
  display: flex;
  justify-content: flex-start;
  height: 40px;
}

.text {
  font-size: 14px;
  color: #1d1d1d;
  display: block;
}

.description {
  color: #15213d;
}

.star-text {
  font-size: 24px;
  margin-left: 6px;
}

.free-text {
  font-size: 14px;
  color: #15213d;
  font-weight: 400;
  margin-top: 26px;
}
.validatedCouponCode {
  display: flex;
  align-items: flex-end;
  width: fit-content;
  background-color: #f1faee;
  padding: 6.6px 10.5px;
  border-radius: 4px;
  margin: auto;
}
.changed-text {
  font-size: 14px;
  color: #b10f15;
  cursor: pointer;
  text-decoration: underline;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
  width: 285px;
}
.checkbox-desktop {
  display: none;
}
.checkbox-without-offers {
  position: absolute;
  bottom: 0px !important;
}

.wrap-currencies-select {
  display: flex;
  align-items: center;
}
.currencies-select {
  padding-right: 20px;
  padding-top: 3px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-position-x: 100%;
  background-position-y: 5px;
  margin-left: 3px;
  cursor: pointer;
  z-index: 20;
  font-size: 14px;
  font-weight: 600;
}

.select-layer {
  background: transparent;
  width: 36px;
  height: 30px;
  margin-left: -54px;
  z-index: 30;
}
.edit-icon {
  width: 14px;
  height: 14px;
}
.free-trial-msg {
  font-size: 11px;
  letter-spacing: 0px;
  color: #15213d;
  margin-left: 40px;
  font-weight: normal;
  margin-bottom: 0px;
}
.address-container {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #000;
  font-weight: normal;
  margin: 32px 0px 28px 0px;
  p {
    margin-bottom: 0px;
    font-weight: normal;
  }
}
.address-title {
  font-size: 18px;
  color: #15213d;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  p {
    font-weight: 500;
  }
}
.address-title-edit {
  font-size: 14px;
  color: #b10f15;
  margin-left: 16px;
  cursor: pointer;
  text-decoration: underline;
}
.avail-free-trial-text {
  color: #b10f15;
  font-weight: 500;
  margin-left: 4px;
}
.free-trial-text {
  font-weight: 500;
}
.wrap-select-edit {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #15213d;
  margin-top: 8px;
  margin-bottom: 0px;
}
.selected-currency {
  width: fit-content;
  margin-bottom: 0px;
  font-weight: 500;
  text-align: end;
  padding-left: 5px;
  padding-right: 2px;
}
.main-text {
  font-size: 37px;
  color: #b10f15;
  font-weight: 500;
  height: 40px;
}
.price-text-container {
  width: fit-content;
}
.review-text {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: $breakpoint-md) {
  .subscription-main-container {
    width: 90%;
  }
  .wrap-input {
    width: 100%;
  }
  .wrap-benefit-and-img {
    display: block;
  }
  .benefit-container {
    width: 100%;
  }
  .image-container {
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }
  .offer-container {
    width: 100%;
  }
  .wrap-change-plane {
    display: block;
  }
  .wrap-change-plane-free {
    display: block;
  }
  .wrap-change-plane-left {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }
  .wrap-change-plane-right {
    text-align: center;
    width: 100%;
  }
  .changed-text {
    width: 100%;
  }
  .checkbox-mobile {
    width: 100%;
  }
  .review-text {
    font-size: 13px;
  }
  .wrap-select-edit {
    justify-content: center;
  }
  .total-amount {
    justify-content: center;
    height: auto;
  }
  .main-text {
    justify-content: center;
  }

  .sub-text {
    white-space: break-spaces;
  }
}
