@import 'styles/variables.module.scss';
.custom-dropdown {
  padding: 0px;
  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: #f6f2f7;
  }
}

.address-container {
  padding: 20px 30px;
  margin: auto;
  font-family: 'Roboto';

  .main-contianer {
    padding: 17px 23px 16px;
    border-radius: 10px;
    border: solid 1px #e6eff8;

    .steps {
      color: #15213d;
      opacity: 0.6;
      font-size: 22px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .main-header {
      color: #15213d;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .sub-header {
      font-size: 14px;
      margin-bottom: 20px;
      color: #15213d;
    }

    .dropdown {
      width: 100%;
      height: 46px;
      border-radius: 4px;
      margin: -4px 0 16px 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      background-color: red;

      :global(.ant-select-selector) {
        height: 46px;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
      :global(.ant-select-selection-item) {
        padding: 8px;
      }
      :global(.ant-select-selection-search-input) {
        height: 46px;
      }
    }

    :global(.ant-select-selector) {
      box-shadow: none !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }

    .split-half {
      margin-top: 8px;

      .item {
        margin-top: 16px;
      }
    }

    .checkbox {
      margin-top: 18px;
      line-height: 24px;
      font-size: 10px;
      margin-bottom: 22px;

      :global(.ant-checkbox-checked) {
        :global(.ant-checkbox-inner) {
          border-color: white !important;
          background-color: #15213d;
        }
      }

      :global(.ant-checkbox-wrapper) {
        display: flex !important;

        :global(.ant-checkbox) {
          margin-top: 6px;
        }

        :global(.ant-checkbox-inner) {
          border-color: #15213d !important;
        }

        :global(.ant-checkbox-checked::after) {
          border: none;
        }
      }
    }

    .button-container {
      text-align: center;

      .add-button {
        width: 100%;
        height: 46px;
        border-radius: 8px;
        background-color: #b10f15;
        border: none;
        color: white;
        font-size: 16px;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

.select-label {
  position: relative;
  top: 7px;
  background: #fff;
  z-index: 2;
  width: fit-content;
  padding: 0px 8px;
  margin-left: 10px;
  color: #837d7d;
}

@media (min-width: $breakpoint-sm) {
  .address-container {
    width: 716px;

    .main-contianer {
      padding: 17px 23px 24px;

      .steps {
        margin-bottom: 18px;
      }

      .sub-header {
        margin-bottom: 14px;
      }

      .split-half {
        display: flex;

        .pr-10 {
          padding-right: 10px !important;
        }

        .pl-20 {
          padding-left: 10px !important;
        }

        .item {
          flex: 0 0 50%;
          padding: 10px 0 0;
          margin-top: unset;
        }
      }

      .checkbox {
        font-size: 14px;
      }

      .add-button {
        width: 246px;
      }
    }
  }
}

.disabled {
  background-color: #d3d3d3 !important;
}
