@import '../../../styles/variables.module.scss';
@import '../../../styles/paymentAddMoneyCommon.module.scss';

.add-money {
  margin: auto;
  text-align: center;
}

.input-field {
  border: none;
  padding: 0 8px 0 16px;
  letter-spacing: 0.5px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  font-size: 16px;
  width: 100%;
  background: #f7f7f7;
  font-weight: 600;
}

.add-money-msg {
  font-size: 15px;
  letter-spacing: 0.02px;
  color: #b2b2b2;
  margin-top: 24px;
  padding: 0 16px;
  line-height: 1.3;
}

.conscent-balance-msg {
  font-size: 18px;
  color: #515b70;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 4px;
}

.coupon-btn {
  color: #14213d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c .selected-currency {
  border-right: 1px solid #dadada;
  opacity: 0.5;
  width: fit-content;
  height: 38px;
  padding: 3px 16px;
  font-size: 20px;
}

.proceed-to-next {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #cccccc43;
  border: 1px solid #cccccc80;
  border-radius: 8px 8px 0px 0px;
  padding: 14px 22px;
}
.heading {
  color: #14213d;
  font-size: 18px;
  font-weight: 600;
}

.btn-text {
  font-size: 18px;
  font-weight: 600;
}

.offers-container {
  background-color: #f1faff;
  padding: 8px 14px;
  margin-top: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 24px;
  font-size: 12px;
  border-left: 1px solid #cccccc80;
  border-right: 1px solid #cccccc80;
  border-bottom: 1px solid #cccccc80;
}
.coupon-text-bold {
  color: #14213d;
  font-weight: bolder;
}

.offers-text {
  color: #ea1800;
  font-weight: bold;
  cursor: pointer;
}
.promotion-text {
  color: #838383;
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: bolder;
}

.preset-amount-container {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px 22px;
  text-align: center;
  margin-bottom: 16px;
}
.add-money-heading {
  color: #15213d;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}

.currencies-select {
  position: relative;
  background: #f7f7f7;
  border: none;
  border-right: 1px solid #cac8c8;
  outline: none;
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  font-weight: 600;
  background: #f7f7f7;

  :global(.ant-select-selector) {
    // height: 22px !important;
    padding: 0 11px 0px 5px !important;
    border: none !important;
  }
  :global(.ant-select-selection-item) {
    line-height: 22px !important;
  }
}

.selected-currency {
  font-size: 20px;
  padding-left: 8px;
  margin-bottom: 0px;
}

.error-msg {
  position: absolute;
  color: #ef2222;
  font-size: 10px;
  font-weight: bold;
  margin-top: 3px;
  width: 83%;
}

.wrap-currency-amount {
  display: flex;
  align-items: center;
  height: 38px;
  background: #fff;
  border-radius: 6px;
  width: 100%;
  margin: auto;
  padding: 8px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 6px;
  select::-ms-expand {
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .add-money-msg {
    font-size: 13px;
    margin-top: 16px;
  }
  .heading {
    font-size: 16px;
  }
  .btn-text {
    font-size: 16px;
  }
  .add-money-heading {
    font-size: 15px;
  }
  .conscent-balance-msg {
    font-size: 14px;
    color: #222;
  }
  .promotion-text {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .proceed-to-next {
    padding: 12px 20px;
  }

  .preset-amount-container {
    padding: 8px 20px;
  }
}

@media (max-width: $breakpoint-xs) {
  .promotion-text {
    font-size: 10px;
    margin-bottom: 4px;
  }
  .conscent-balance-msg {
    margin-bottom: 12px;
    margin-top: 4px;
  }
  .offers-container {
    margin-bottom: 16px;
  }
}
