@import '../styles/variables.module.scss';

.container {
  min-width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  position: relative;
  opacity: 1;
}
.code {
  display: flex;
  justify-content: left;
}
.showcode {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.midcontainer {
  margin-top: 200px;
  margin: auto;
  background-color: #f5faff;
  width: 623px;
  height: 773px;
  min-height: 100%;
  border: 1px solid #afcbea;
  border-radius: 10px;
  padding: 20px;
  opacity: 1;
}
.subsheading {
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font: normal normal medium 24px/28px Roboto;
  letter-spacing: 0px;
  color: #b10f15;
  opacity: 1;
  padding-bottom: 20px;
}
.details {
  height: 21px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #15213d;
}
.address {
  width: 200px;
  margin-bottom: 30px;
}
.plans {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  line-height: 3.5;
  font: normal normal medium 14px/24px Roboto;
  border-bottom: 0.5px solid #c9c2c2;
  font-size: 14px;
  font-weight: 500;
}
.mobileinput {
  width: 230px;
  height: 46px;
  margin-top: 10px;
  text-align: left;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.confirm {
  width: 219.5px;
  height: 46px;
  margin: 16px 19.3px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #b10f15;
  font-size: 16px;
  cursor: pointer;
}
.not-confirm {
  @extend .confirm;
  background-color: #6a6a6a;
}
.changePlan {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.editDetails-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  padding-top: 80px;
  z-index: 1000;
}
.inner-container {
  width: 465px;
  height: auto;
  position: relative;
  padding: 32px;
  border-radius: 12px;
  background-color: #fff;
}
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  opacity: 0.75;
}
.cross-icon-container {
  cursor: pointer;
  position: absolute;
  left: 445px;
  top: -12px;
}
.cross-icon {
  font-size: 15px;
  width: 34px;
  height: 34px;
  border: 3px solid #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 600;
}
.data-container {
  line-height: 2;
}
.full-width-input {
  @extend .mobileinput;
  width: 100%;
}
.half-width-input {
  @extend .mobileinput;
  // margin-right: 20px;
  width: 48%;
}
.half-width-input-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
.cancel-save-container {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}
.save-buton {
  @extend .confirm;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  background-color: black;
  cursor: pointer;
}
.cancel-buton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.smallfont {
  color: #6a6a6a;
  opacity: 1;
  font-size: 12px;
}
.emailInput {
  width: 25rem;
  height: 46px;
  margin-top: 10px;
  padding-left: 15px;
  text-align: left;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
@media only screen and (max-width: $breakpoint-lg) {
  .emailInput {
    width: 20rem;
  }
  .amount {
    padding: 20px;
  }
  .price {
    padding: 18px;
    margin-top: -15px;
  }
  .data {
    display: flex;
  }
}
