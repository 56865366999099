@import '../../src/styles/variables.module.scss';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 70px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.content {
  text-align: center;
  color: #12223d;
}

.main-heading {
  font-size: 120px;
  color: #1bb5c9;
  margin-bottom: 40px;
}

.heading {
  font-size: 35px;
  margin-bottom: 0;
}

.data-icon {
  width: 100%;
  margin: auto;
}

.sub-heading {
  font-size: 20px;
}

@media (max-width: $breakpoint-md) {
  .data-icon {
    width: 45%;
  }

  .main-heading {
    font-size: 60px;
  }

  .heading {
    font-size: 25px;
    margin-bottom: 0;
  }

  .sub-heading {
    font-size: 16px;
  }
}
