@import '../../../styles/variables.module.scss';

.symbol-amount-main {
  max-width: 450px;
  margin: auto;
}

.symbol-amount-container {
  display: flex;
  border: 1px solid #707070;
  width: 100%;
}

.symbol-amount-label {
  font-size: 12px;
  opacity: 0.66;
  text-align: left;
  margin-bottom: 8px;
}

.symbol-amount-footer {
  font-size: 14px;
  opacity: 0.66;
  text-align: center;
  margin-top: 2px;
}

.footer-large {
  font-size: 12px;
  margin-bottom: 32px;
}

.currencies-select {
  position: relative;
  background-color: #fff;
  border: none;
  border-right: 1px solid #707070;
  outline: none;
  padding: 8px;
  width: fit-content;
  font-size: 16px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.amount-input {
  border: none;
  padding: 0 8px 0 32px;
  letter-spacing: 0.5px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  font-size: 20px;
  width: 100%;
}

@media (max-width: $breakpoint-sm) {
  .symbol-amount-footer {
    font-size: 12px;
  }
}
