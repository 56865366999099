@import 'styles/variables.module.scss';

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  font-family: 'Roboto';
}

.content-section {
  width: 420px;
  background-color: white;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  padding: 42px 45px;
}
@media only screen and (max-width: $breakpoint-md) and (min-width: 399px) {
  .content-section {
    margin: 0 16px;
    padding: 24px 40px;
  }
}
@media (max-width: 400px) {
  .content-section {
    margin: 0 16px;
    padding: 24px 16px;
  }
}

.heading {
  font-size: 20px;
  font-weight: 600;
  color: #15213d;
}

.vertical-line {
  border-right: none;
  border-bottom: 1px dashed #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
}

.or-write {
  background-color: #fff;
  width: 50px;
  height: 25px;
  position: relative;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  top: 12px;
  text-align: center;
}

.inp-wrap {
  margin-top: 60px;
  width: 100%;
}

.otp-request {
  font-size: 13px;
  color: #000;
  margin-top: 8px;
  text-align: end;
}

.cursor-blue-link {
  cursor: pointer;
  color: #19b4c8;
  font-size: 16px;
  margin-left: 5 px;
}

@media (max-width: $breakpoint-sm) {
  .vertical-line {
    margin-top: 18px;
    margin-bottom: 24px;
  }

  .inp-wrap {
    margin-top: 40px;
  }
}
