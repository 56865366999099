@import '../../styles/variables.module.scss';

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 80px;
  margin-top: 10px;
}

.site-layout {
  position: absolute;
  width: 100%;
  height: 100vh;

  :global(.ant-layout-header) {
    background: #fff;
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 2000;
  }

  :global(.ant-menu) {
    font-size: 20px;
    font-weight: 600;
    text-align: end;
  }

  :global(.ant-menu-vertical) {
    border-right: none;
  }

  :global(.ant-menu-horizontal) {
    line-height: 0px;
  }

  :global(.ant-menu.ant-menu-dark) {
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
  }

  :global(.ant-menu-dark .ant-menu-item) {
    color: #000;
    text-align: center;

    &:hover {
      color: #000;
    }
  }

  :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background-color: transparent;
  }

  :global(.ant-menu.ant-menu-dark .ant-menu-item-selected) {
    background: transparent;
    color: #19b4c8;
    text-align: center;
  }

  :global(.ant-menu.ant-menu-dark .ant-menu-item-selected svg) {
    fill: #19b4c8;
  }

  :global(.ant-menu.ant-menu-dark .ant-menu-item-selected g) {
    stroke: #19b4c8;
  }

  :global(.ant-menu.ant-menu-dark .ant-menu-item-selected .a),
  :global(.ant-menu.ant-menu-dark .ant-menu-item-selected .b) {
    stroke: #19b4c8;
  }

  :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover) {
    background-color: transparent;
  }

  :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover svg) {
    fill: #19b4c8;
  }

  :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover g) {
    stroke: #19b4c8;
  }

  :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover .a),
  :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover .b) {
    stroke: #19b4c8;
  }
}

.custom-bg {
  background-color: white;
  padding-top: 155px;
}

.set-svg-icon {
  width: 35px;
  height: 35px;
}

.header-icon {
  width: 70px;
  height: 70px;
  box-shadow: 1px 1px 6px #cbe3e6;
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title {
  height: 10px;
  margin-top: -13px;
  font-size: 16px;
  font-weight: normal;
}

.msg-icon-container {
  position: fixed;
  bottom: 70px;
  right: 50px;
  background-color: #fff;
  padding: 2px;
  border-radius: 100%;
}

.antd-msg-icon {
  font-size: 40px !important;
  color: #19b4c8 !important;
}

@media (max-width: $breakpoint-sm) {
  .site-layout {
    :global(.ant-layout-header) {
      height: 100px;
      padding: 18px 0 0 0;
    }

    :global(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item) {
      padding: 0 10px;
    }
  }

  .logo {
    height: 40px;
    margin-left: 10px;
    margin-top: -10px;
  }

  .custom-bg {
    padding-top: 130px;
  }

  .header-icon {
    width: 45px;
    height: 45px;
  }

  .header-title {
    font-size: 12px;
  }

  .set-svg-icon {
    width: 25px;
  }

  .msg-icon-container {
    bottom: 36px;
    right: 20px;
  }

  .antd-msg-icon {
    font-size: 35px !important;
  }
}
