$breakpoint-xxs: 320px;
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-paywall: 749px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;
$input-font-size-small: 16px;
$input-font-size: 18px;
$button-primary-color: #0353e9;
$button-primary-hover-background: transparent linear-gradient(247deg, #3d8dfa 0%, #005bd8 100%) 0% 0% no-repeat
  padding-box;
$button-primary-hover-box-shadow: 0px 4px 10px #00000038;
$button-primary-hover-box-opacity: 1;
$button-secondary-color: #ededed 0% 0% no-repeat padding-box;
