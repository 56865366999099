@import 'styles/variables.module.scss';
@import '../../views/Auth/addInfo.module.scss';

.main-container {
  position: relative;
  width: 443px;
  margin: auto;
  padding-top: 16px;
}
.container {
  box-shadow: 0 3px 6px #b1d6f8b3;
  background-color: #fff;
  text-align: center;
  padding: 50px 20px 0 20px;
  max-width: 100%;
  width: 443px;
  margin: auto;
  border-radius: 10px;
  padding-bottom: 32px;
  font-family: 'Roboto', sans-serif;
}

.welcome-text {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 25px;
  line-height: 1.35;
  padding-top: 10px;
}

.logo-icon {
  width: 30%;
  height: 30%;
}

.inp-field {
  display: flex;
  border-bottom: 1px solid rgb(228 228 228);
  width: 270;
  padding: 8px;
  margin-top: 30px;
}

.phone-code {
  color: #1d2226;
  opacity: 0.5;

  font-size: 17px;
}
.right-border {
  border-right: 1px solid grey;
  margin-left: 8px;
  height: 24px;
  opacity: 0.5;
}

.phone-inp-box {
  outline: none;
  border: none;
  margin-left: 12px;
}

.chaged-text {
  color: #15213d;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 28px;
  cursor: pointer;
}

.otp-box {
  outline: none;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #c7c7c7;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 14px;
}
.error-msg {
  color: red;
  font-size: 12px;
}

.request-text {
  font-size: 13px;
  margin-bottom: 80px;
}
.cursor-blue-link {
  cursor: pointer;
  border-bottom: 1px solid;
  line-height: 2;
}
.add-info-sub-text {
  width: max-content;
}
.cross-icon-container {
  left: 372px;
  top: 8px;
  text-align: end;
  cursor: pointer;
  position: absolute;
  z-index: 2;
}
.cross-icon {
  font-size: 15px;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-radius: 100%;
  background-color: #a5a5a5;
  // box-shadow: 0 3px 6px #b1d6f8b3;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 600;
}
.input-padding {
  padding-left: 33px;
}

@media (max-width: $breakpoint-sm) {
  .main-container {
    width: 320px;
  }
  .container {
    width: 320px;
  }
}
.icon-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 7px #00000045;
  border-radius: 40px;
  width: 280px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  img {
    width: 28px;
  }
}
@media (max-width: 350px) {
  .container {
    width: 300px;
  }
}
@media (max-width: $breakpoint-xs) {
  .cross-icon-container {
    top: 4px;
    left: 276px;
  }
}
