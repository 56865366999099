@import 'styles/variables.module.scss';

.container {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0d0f11;
  font-family: Roboto, sans-serif;
}

.container-wrapper {
  background-color: #fff;
  text-align: center;
  max-width: 100%;
  width: 550px;
  margin: auto;
  border-radius: 10px;
  padding: 50px 32px 20px;

  :global(.ant-checkbox-checked) {
    :global(.ant-checkbox-inner) {
      border-color: white !important;
      background-color: #15213d;
    }
  }
}
.heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.38;
}
.middle-text {
  display: flex;
  font-size: 18px;
  justify-content: center;
  p {
    width: 115px;
    text-align: right;
    margin-bottom: 8px;
  }
  span {
    text-align: left;
    color: #b10f15;
    margin-left: 16px;
    font-weight: 500;
  }
}

.color-box {
  width: 100%;
  border-radius: 10px;
  border: solid 1px #afcbea;
  background-color: #f5faff;
  padding: 16px;
  margin-top: 24px;
}
.color-box-heading {
  font-size: 20px;
  font-weight: 500;
  color: #b10f14f5;
}

.small-text {
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #15213d;
}

.offer-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 1.71;
  color: #15213d;
}
.continue-text {
  color: #b10f15;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
}
.inline-item-check {
  display: flex;
  p {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
  }
}
.inline-item {
  display: flex;
  border-radius: 7px;
  border: solid 1px #d3d3d3;
  background-color: #fff;
  padding: 16px;
  align-items: center;
  margin-top: 10px;
  p {
    text-align: left;
    margin-left: 16px;
    font-size: 14px;
    margin-bottom: 0px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
}

@media (max-width: $breakpoint-sm) {
  .container-wrapper {
    padding: 50px 16px 20px;
  }

  .heading {
    font-size: 20px;
  }

  .inline-item {
    p {
      font-size: 12px;
    }
  }
}
