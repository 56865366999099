@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100;300;400;500;700&family=Eczar:wght@400;500;700&family=Hind+Madurai:wght@300;400;500;700&family=Hind+Siliguri:wght@300;400;500;700&family=Laila:wght@300;400;500;700&family=Noto+Sans+Malayalam:wght@100;300;400;500;700&family=Noto+Sans+Tamil:wght@100;300;400;500;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rozha+One&family=Tiro+Bangla:ital@0;1&family=Tiro+Telugu:ital@0;1&display=swap');

.main-div-focus {
  outline: 8px solid red;
  outline-offset: 16px;
}
.disabled-button {
  opacity: 0.5;
  cursor: no-drop;
}
#couponRightText,
#loginText,
#contentDivBtn,
#passDivBtn,
#subDivBtn,
#RadioBtnOne,
#RadioBtnTwo,
#RadioBtnThree,
#mainSubmitBtn {
  cursor: pointer;
}
.text-focus {
  border: 2px solid #fff;
  padding: 4px 8px;
}
.button-focus {
  outline: 2px solid #fff;
  outline-offset: 5px;
}
.conscent-balance-one {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
}

.conscent-balance-2FB {
  @extend .conscent-balance-one;
  padding: 0px;
  margin: 12px 0px;
}

.insufficient-balance {
  font-size: 14px;
  color: red;
  font-weight: normal;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
  margin-bottom: 10px;
  margin-top: 2px;
  display: none;
}
.conscent-balance-two {
  @extend .conscent-balance-one;
  margin-top: 16px;
  margin-bottom: 0px;
}
.conscent-balance-mobile {
  @extend .conscent-balance-one;
  margin-top: 32px;
  margin-bottom: 0px;
}
.button-focus-blue {
  outline: 3px solid blue;
}
.button-focus-blue-none {
  .button-focus-blue {
    outline: none;
  }
}
.hide-radio-btn {
  visibility: hidden;
}
.customize-radio-btn {
  width: 24px;
  height: 24px;
}
.customize-radio-btn-two {
  width: 16px;
  height: 12px;
}
.mobile-common-one {
  width: 320px;
  text-align: center;
  background-color: rgb(19, 16, 16);
  padding: 16px 0px;
  font-family: Roboto;
  color: #fff;
  box-sizing: content-box !important;
}

.mobile-coupon-text {
  font-family: Roboto;
  font-size: 13px;
  line-height: 1.57;
  color: #6e6e6e;
  text-align: center;
  margin-right: 4px;
}
.mobile-click-here {
  color: #adadad;
}
.mobile-purchase-text {
  margin-right: 8px;
  font-size: 13px;
  color: #6e6e6e;
  text-align: center;
  font-weight: bold;
  margin-top: 12px;
}

.mobile-pay-with {
  width: 100%;
  height: 8px;
  font-size: 7px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0px 24px;
}
.mobile-consCentLogo {
  width: 50px;
  height: 10px;
  margin-left: 4px;
}

.temp-1fb-headline-title {
  width: 100%;
  height: 19px;
  margin: 0 10px 0 0;
  opacity: 0.4;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  color: #fff;
}
.temp-1fb-headline-subtitle {
  width: 701px;
  height: 40px;
  margin: 10px 114.8px 30px 223px;
  font-family: PlayfairDisplay;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.temp-1fb-pricing-box {
  width: 40%;
  margin: 0px 8px;
  padding: 16px;
  border-radius: 14px;
  background-color: #000;
  text-align: center;
}
.temp-1fb-pricing-box-one {
  @extend .temp-1fb-pricing-box;
  width: 90%;
  margin: auto;
}
.temp-1fb-pricing-box-two {
  @extend .temp-1fb-pricing-box;
  width: 45%;
}
.temp-1fb-wrapper {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  // box-shadow: 6px 3px 22px 0 rgba(137, 137, 137, 0.16);
  background-color: #212426;
}
.temp-1fb-wrap-content {
  display: flex;
  justify-content: center;
}
.temp-1fb-pricing-point {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
}
.temp-1fb-desc-text {
  line-height: 1.43;
  color: #fff;
  margin-top: 16px;
}
.temp-1fb-button-buy-now {
  width: 75%;
  margin: 8px auto;
  border: none;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.temp-1fb-pay-with {
  width: 48%;
  margin: 0 4.1px 0 0;
  font-size: 7px;
  line-height: normal;
  text-align: right;
  color: #fff;
}
.temp-1fb-coupon-text {
  width: 100%;
  line-height: 1.57;
  color: #6e6e6e;
  padding: 16px 8px;
}
.temp-1fb-click-here {
  color: #adadad;
  text-decoration: underline;
}
//3a start
.temp-3a-headline-title {
  font-size: 22px;
  margin: 0 0 18px;
  font-weight: bold;
  line-height: 1;
}
.temp-3a-headline-subtitle {
  margin: 18px 22px 0 23px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.temp-3a-pricing-box {
  width: 160px;
  margin: 8px;
  padding: 16px 8px;
  border-radius: 6px;
  border: solid 1px #707070;
  background-color: #131419;
}
.temp-3a-price-container {
  display: flex;
}
.temp-3a-wrap-content {
  display: flex;
  width: auto;
  height: auto;
  overflow-x: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.temp-3a-pricing-option {
  display: block;
}
.temp-3a-pricing-point {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #0164b1;
}
.temp-3a-button-buy-now {
  margin-top: 24px;
  border-radius: 6px;
  background-color: #0164b1;
  border: none;
  width: 80%;
  margin: auto;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.temp-3a-coupon-text {
  width: 100%;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.57;
  color: #6e6e6e;
  text-align: center;
}
.temp-3a-click-here {
  color: #adadad;
  text-decoration: underline;
}
.temp-3a-purchase-text {
  width: 100%;
  height: 16px;
  margin: 10px 0 12px 0px;
  font-family: Roboto;
  font-size: 14px;
  color: #6e6e6e;
  text-align: center;
}
.temp-3a-res-wrap {
  max-width: 450px;
  background-color: Black;
  padding: 24px 16px;
  margin: auto;
  text-align: center;
}
@media (max-width: 388px) {
  .temp-3a-res-wrap {
    padding: 19px 9px;
  }
  .temp-3a-pricing-point {
    width: 100%;
  }
  .temp-3a-pricing-box {
    width: 100%;
  }
  .temp-3a-pricing-option {
    text-align: center;
    height: auto;
  }
}

//3a end
// 3fb start
.temp-3fb-main-container {
  background-color: #212426;
  font-family: Roboto;
  padding: 10px 10px;
}

.temp-3fb-Login {
  margin-left: 7px;
  opacity: 0.7;
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: #fff;
  text-decoration: underline;
}

.temp-3fb-sub-heading {
  font-family: PlayfairDisplay;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.87;
  text-align: center;
  color: #fff;
}

.temp-3fb-heading-wrapper {
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #fff;
}

.temp-3fb-sub-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.temp-3fb-offers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.temp-3fb-passes-section {
  text-align: left;
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #2b2e30;
}

.temp-3fb-Weekly-Pass {
  margin-top: -5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: left;
  color: #0164b1;
  margin-left: 10px;
}

.temp-3fb-price {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: right;
  color: #0164b1;
  width: 100px;
  inline-size: -webkit-fill-available;
}

.temp-3fb-pair {
  display: flex;
  justify-content: space-between;
}

.temp-3fb-pass-content {
  font-size: 14px;
  line-height: 1.57;
  text-align: left;
  color: #fff;
}
.temp-3fb-week-passes {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.temp-3fb-wrap-inp {
  display: flex;
  align-items: baseline;
}

.temp-3fb-upd-price {
  position: relative;
  color: #0164b1;
  font-size: 14px;
}

.temp-3fb-upd-price:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: #8f969a;
  transform: rotate(9deg);
}

.temp-3fb-btn {
  width: 620px;
  padding: 13px 276px 12px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
}

.temp-3fb-bottom-section {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.temp-3fb-Buy-now {
  width: 68px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
}

.temp-3fb-Pay-with {
  height: 8px;
  margin-right: 82px;
  float: right;
  font-size: 7px;
  text-align: right;
  color: #fff;
  display: flex;
}

.temp-3fb-coupon {
  width: 200px;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}

.temp-3fb-coupon .temp-3fb-coupon-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 5px;
}

@media (min-width: 990px) and (max-width: 1400px) {
  .temp-3fb-offers {
    width: 80%;
  }
  .temp-3fb-passes-section {
    width: 95%;
  }
  .temp-3fb-Weekly-Pass {
    font-size: 15px;
  }
  .temp-3fb-price {
    font-size: 15px;
  }
}

@media (min-width: 750px) and (max-width: 990px) {
  .temp-3fb-offers {
    width: 80%;
  }
  .temp-3fb-passes-section {
    width: 95%;
  }
  .temp-3fb-Weekly-Pass {
    font-size: 15px !important;
  }
  .temp-3fb-price {
    font-size: 15px;
  }
}

@media (min-width: 1400px) and (max-width: 2400px) {
  .temp-3fb-bottom-section {
    width: 100%;
  }
  .temp-3fb-passes-section {
    width: 90%;
  }
}

// 3fb end

//5a start

.temp-5a-offer-info {
  width: 100%;
}

.temp-5a-price {
  width: 100%;
  margin: 8px 0 8px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}
.temp-5a-wrap-discount {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.temp-5a-discount {
  font-size: 10px;
  font-weight: 300;
  line-height: 2.4;
  text-align: center;
  color: #e3e3e3;
}

.temp-5a-amt {
  margin-left: 8px;
  font-size: 10px;
  font-weight: 300;
  line-height: 2.4;
  text-align: center;
  color: #696868;
  text-decoration: line-through;
}

.temp-5a-offer-text {
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  padding: 0px 8px;
  box-sizing: content-box;
}

.temp-5a-wrap-bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.temp-5a-btn-section {
  width: 100%;
  margin: 16px 0 16px;
  padding: 10px 8px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
}

.trial {
  display: flex;
  align-items: center;
}

.temp-5a-Pay-with {
  width: 28px;
  height: 8px;
  margin: 0 4.1px 0 0;
  font-size: 7px;
  text-align: right;
  color: #fff;
}

.temp-5a-footer {
  width: 198px;
  height: 33px;
  margin: 22px 60px 0 61px;
}

.temp-5a-coupon {
  width: 198px;
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}

.temp-5a-coupon .temp-5a-coupon-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}

.temp-5a-Annual {
  width: 50px;
  font-size: 15px;
  font-weight: bold;
  line-height: 2.5;
  text-align: center;
  color: #0164b1;
}

.temp-5a-purchased {
  width: 164px;
  margin: 12px 17px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}

.temp-5a-purchased .temp-5a-coupon-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}

@media (min-width: 320px) and (max-width: 549px) {
  .temp-5a-btn-section {
    width: 100%;
  }
  .temp-5a-main-container {
    width: 100%;
    padding: 16px;
  }
  .temp-5a-pass2 {
    width: 32%;
    margin: -5px;
  }
}

@media (min-width: 368px) {
  .temp-5a-offers {
    width: 100%;
  }
}
//5a end
// 5b start
.temp-5b-main-container {
  width: 100%;
  padding: 32px 8px;
  background-color: #000;
  font-family: Roboto;
}
.temp-5b-offer-section {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}
.temp-5b-offer-header {
  width: 100%;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.temp-5b-rec-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-5b-wrap-body {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 46px 0 rgb(255 255 255 / 16%);
  background-color: #2b2e30;
  margin: -8px 10px;
}
.temp-5b-amt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  padding-top: 10px;
  color: #0164b1;
}
.temp-5b-discounts {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
.temp-5b-off {
  margin: 0 9px 0 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: #e3e3e3;
}
.temp-5b-price {
  margin: 0 0 0 9px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: #696868;
  text-decoration: line-through;
}
.temp-5b-offer-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 0px;
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: #fff;
}
.temp-5b-coupon-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.temp-5b-coupon {
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}
.temp-5b-coupon-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}
.temp-5b-bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}
.temp-5b-btn-section {
  width: 40%;
  padding: 12px 0px 12px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgb(0 0 0 / 16%);
  background-color: #0164b1;
}
.temp-5b-Buy-now {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
}
.temp-5b-Pay-with {
  width: 10%;
  height: 8px;
  font-size: 7px;
  color: #fff;
  margin-top: 10px;
}
.temp-5b-best-offer {
  display: flex;
  background-color: #b52c2c;
  height: 33.3px;
  width: 98%;
  padding: 16px;
  position: relative;
  justify-content: center;
  align-items: center;
  color: white;
  clip-path: polygon(100% 20%, 100% 0%, 0% 0%, 8% 50%, 0% 100%, 20% 100%, 100% 100%);
}

@media (min-width: 750px) and (max-width: 950px) {
  .temp-5b-btn-section {
    width: 65%;
  }
}

// 5b end
//5fb start
.temp-5fb-main-container {
  width: 100%;
  padding: 32px 8px;
  background-color: #212426;
  font-family: Roboto;
}
.temp-5fb-wrap-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.temp-5fb-wrap-heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.temp-5fb-heading {
  margin-right: 10px;
  font-size: 15px;
  line-height: 1.38;
  color: #fff;
}

.temp-5b-heading {
  @extend .temp-5fb-heading;
  margin: 0px 10px 10px 0px;
}
.temp-5fb-Login {
  opacity: 0.7;
  font-size: 16px;
  line-height: 1.38;
  color: #fff;
}
.temp-5fb-sub-container1 {
  display: flex;
  justify-content: space-between;
}

.temp-2fb-sub-container1 {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.temp-5fb-wrap-body {
  display: flex;
  align-items: center;
}
.temp-5fb-passes {
  display: flex;
  position: relative;
  text-align: left;
  align-items: baseline;
}
.temp-5fb-passes-info {
  margin: 15px 10px 2px 32px;
  font-size: 14px;
  line-height: 1.57;
  text-align: left;
  color: #fff;
}
.temp-5fb-sub-heading {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.87;
  text-align: center;
  color: #fff;
}
.temp-5fb-offers-section {
  width: 50%;
  padding: 10px 5px 12px;
  margin-right: 12px;
  border-radius: 10px;
  background-color: #0d0d0d;
}

.temp-5fb-btn-section {
  position: relative;
  width: 206px;
  padding: 10px;
  margin: 0 0 0px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgb(0 0 0 / 16%);
  background-color: #0164b1;
  text-align: center;
  box-sizing: content-box;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.temp-2fb-btn-section {
  @extend .temp-5fb-btn-section;
  width: 45%;
  margin: auto;
  margin-top: 24px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.temp-5fb-Buy-now {
  width: 68px;
  height: 21px;
  font-size: 16px;
  line-height: 1.11;
}
.temp-5fb-Pay-with {
  padding-top: 10px;
  width: auto;
  font-size: 7px;
  color: #fff;
  text-align: center;
}
.temp-2fb-offers-section {
  @extend .temp-5fb-offers-section;
  width: 45%;
  margin: 0px 16px;
}
//5fb end
//5a start
.temp-5a-main-container {
  padding: 16px;
  // border: solid 1px #707070;
  background-color: #131419;
  font-family: Roboto;
  max-width: 450px;
  margin: auto;
  box-sizing: border-box;
}
.temp-5a-wrap-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.temp-5a-main-heading {
  margin: 0 0 4px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}

.temp-5a-sub-heading {
  font-size: 12px;
  line-height: 1.67;
  text-align: center;
  color: #fff;
}

.temp-5a-offers {
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0 0 11px;
  border-radius: 5px;
}

.temp-5a-offers-new {
  @extend .temp-5a-offers;
  align-items: end;
  margin-top: 24px;
}

.temp-5a-pass2 {
  margin: 0px 4px;
  padding: 0 0 11px;
  border-radius: 5px;
  background-color: #242324;
  width: 33%;
}
.temp-5a-2cta {
  @extend .temp-5a-pass2;
  width: 100%;
  margin: 0px 10px;
}

.temp-5a-passes {
  width: 100%;
  text-align: center;
  border-radius: 2px 2px 0px 0px;
}

.temp-5a-Polygon {
  width: 10px;
  height: 6px;
  margin: 40px 42px;
  border-radius: 0.2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(45deg);
}

.temp-5a-Weekly-Pass {
  width: 92px;
  font-size: 15px;
  font-weight: bold;
  line-height: 2.5;
  text-align: center;
  color: #0164b1;
}
.temp-5fb-Weekly-Pass {
  @extend .temp-5fb-Weekly-Pass;
  color: #fff;
  margin-left: 10px;
  font-weight: 600;
}

.temp-7a-container {
  @extend .mobile-common-one;
}

.temp-7a-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  width: 300px;
  margin: auto;
}
.temp-7a-sub-heading {
  font-size: 12px;
  line-height: 1.6;
  width: 300px;
  margin: auto;
}
.temp-7a-subscribe-button {
  margin-top: 24px;
  border-radius: 6px;
  background-color: #0164b1;
  border: none;
  width: 90%;
  margin: auto;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.temp-7a-subscribe-text {
  width: 82px;
  height: 21px;
  font-size: 18px;
  line-height: 1.11;
}
.temp-7a-pay-with {
  margin: 20px 110px 30px 0px;
}

.temp-8-container {
  @extend .mobile-common-one;
}

.temp-8-heading {
  width: 243px;
  padding: 10px;
  font-family: PlayfairDisplay;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: #fff;
}
.temp-8-text-div {
  width: 100%;
}
.temp-8-inner-div {
  padding-right: 12px;
}
.temp-8-outer-div {
  display: flex;
  padding-left: 12px;
  white-space: nowrap;
}
.temp-8-text {
  white-space: normal;
  font-size: 16px;
  line-height: 2;
  text-align: left;
  color: #fff;
}
.temp-8-currency1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  color: #fff;
}
.temp-8-currency2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: #fff;
  width: fit-content;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    color: #fff;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}
.temp-8-radio {
  :global(.ant-radio) {
    margin: 0px 0px -12px 16px;
    color: #0164b1;
  }
}
.ant-radio-inner::after {
  background-color: red;
}
.temp-8-subscribe-button {
  width: 90%;
  margin: auto;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 10px;
}
.temp-8-subscribe-text {
  width: 82px;
  font-size: 18px;
  line-height: 1.11;
}
.temp-8-pay-with {
  height: 8px;
  width: 100%;
  margin: 20px 150px 30px 0px;
  font-family: Roboto;
  font-size: 7px;
  color: #fff;
  white-space: nowrap;
}
.temp-8-consCentLogo {
  width: 50px;
  height: 10px;
}

.temp-8a-container {
  @extend .mobile-common-one;
}
.temp-8a-heading {
  width: 243px;
  font-family: PlayfairDisplay;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  padding-left: 20px;
}
.temp-8a-outer-div {
  display: flex;
  white-space: nowrap;
  padding: 0px 8px;
  margin-bottom: 14px;
}
.temp-8a-text {
  width: 100%;
  font-size: 16px;
  line-height: 2;
  text-align: left;
  color: #fff;
}
.temp-8a-currency1 {
  margin: 0px 0 0 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.temp-8a-inner-div {
  padding-left: 8px;
}
.temp-8a-currency2 {
  margin: 0px 0 0 26px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  text-align: center;
  color: #fff;
  width: fit-content;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    color: #fff;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}
.temp-8a-radio {
  :global(.ant-radio) {
    margin: 0px 0px -12px 22px;
    color: #0164b1;
  }
}
.temp-8a-subscribe-button {
  width: 90%;
  margin: auto;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  border: none;
  padding: 8px 10px;
}
.temp-8a-subscribe-text {
  width: 82px;
  font-size: 18px;
  line-height: 1.11;
}
.temp-8a-pay-with {
  height: 8px;
  width: 100%;
  margin: 20px 150px 30px 0px;
  font-family: Roboto;
  font-size: 7px;
  // text-align: right;
  color: #fff;
  white-space: nowrap;
}
.temp-8a-consCentLogo {
  width: 50px;
  height: 10px;
}
@media screen and (min-width: 320px) {
  .temp-8a-currency1 {
    margin: 0px 28px 0px 0px;
  }
  .temp-8a-currency2 {
    margin: 0px 0px 0px 4px;
  }

  .temp-8a-radio {
    :global(.ant-radio) {
      margin: 0px 0px -12px -3px;
    }
  }
}

.temp-13a-main-container {
  padding: 16px;
  border: solid 1px #707070;
  background-color: #131419;
  font-family: Roboto;
  max-width: 450px;
  margin: auto;
}
.timer-5fb {
  border: 0px;
}
.temp-13a-main-heading {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #fff;
}
.temp-13a-wrap-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.temp-13a-sub-heading {
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.83;
  text-align: center;
  color: #fff;
}
.temp-13a-offer-section {
  width: 60%;
  margin: 10px 50px;
  padding: 0 0 11px;
  border-radius: 5px;
  background-color: #2b2b2b;
  box-sizing: content-box;
}
.temp-13a-passes {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 2px 2px 0px 0px;
  margin: 0 0 12px;
  background-color: #fff;
  position: relative;
}
.temp-13a-Polygon {
  width: 5%;
  height: 6px;
  top: 0px;
  margin: 44px 94px;
  border-radius: 0.2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(45deg);
}
.temp-13a-subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 2.75;
  text-align: center;
  color: #0164b1;
}
.temp-13a-amt {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}
.temp-13a-discount {
  margin: 0 10px 0 0;
  font-size: 10px;
  font-weight: 300;
  line-height: 2.4;
  text-align: center;
  color: #e3e3e3;
}
.temp-13a-price {
  margin: 0 0 0 10px;
  font-size: 10px;
  font-weight: 300;
  line-height: 2.4;
  text-align: center;
  color: #696868;
  text-decoration: line-through;
}
.temp-13a-pass-info {
  font-size: 8px;
  line-height: 1.25;
  text-align: center;
  color: #fff;
  padding: 8px;
}
.temp-13a-btn-section {
  margin: 8px auto;
  padding: 8px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.temp-13a-amt-offer {
  display: flex;
  justify-content: center;
}
.temp-13a-Buy-now {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
}
.temp-13a-Pay-with {
  margin: 0 4.1px 0 0;
  font-size: 7px;
  color: #fff;
  text-align: center;
}
.temp-13a-footer {
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
  padding-top: 18px;
}
.temp-13a-footer .temp-13a-footer-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}
.temp-13a-purchased {
  margin: 12px 17px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}
.temp-13a-purchased .temp-13a-footer-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}
@media (min-width: 319px) and (max-width: 414px) {
  .temp-13a-pass-info {
    padding-top: 9px;
  }
}

.temp-15a-container {
  @extend .mobile-common-one;
  max-width: 450px;
  padding: 24px;
}
.temp-15a-heading {
  font-family: PlayfairDisplay;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  color: #fff;
}
.temp-15a-timer {
  width: 100%;
  margin: 0 auto;
}
.temp-15a-sub-heading {
  height: 36px;
  font-family: Roboto;
  font-size: 12px;
  color: #fff;
}
.temp-15a-countDown-div {
  width: 145px;
  height: 34px;
  margin: 1px 0 4px 115px;
  opacity: 0.28;
  border-radius: 4px;
  background-color: #eaedef;
}
.temp-15a-countDown {
  padding: 8px 0px 0px 0px;
  margin: 0px 6px 4px 7px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 2.1px;
  text-align: center;
  color: #fff;
}
.temp-15a-currency {
  font-size: 51px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #fff;
  padding: 0px 16px;
}
.temp-15a-off-div {
  display: flex;
  justify-content: center;
}
.temp-15a-wrap-off-text {
  display: flex;
  justify-content: center;
  padding: 0px 16px;
  align-items: baseline;
}
.temp-15a-off-text {
  font-size: 17px;
  font-weight: 300;
  color: #e3e3e3;
  white-space: nowrap;
}
.temp-15a-copyright {
  margin-left: 12px;
  font-size: 17px;
  font-weight: 300;
  color: #696868;
  position: relative;
}
.temp-15a-off-sub-text {
  font-size: 17px;
  font-weight: 300;
  color: #696868;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: #727272;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.temp-15a-subscribe-button {
  width: 90%;
  height: 46px;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #0164b1;
  border: none;
}
.temp-15a-pay-with {
  width: 100%;
  font-family: Roboto;
  font-size: 7px;
  padding: 10px;
  color: #fff;
  white-space: nowrap;
}
.temp-15a-consCentLogo {
  width: 50px;
  height: 10px;
}
.temp-15a-coupon-text {
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  color: #6e6e6e;
}
.temp-15a-click-here {
  color: #adadad;
  text-decoration: underline;
}
.temp-15a-purchase-text {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #6e6e6e;
  text-align: center;
  font-weight: bold;
  color: #6e6e6e;
  text-align: center;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .temp-15a-subscribe-button {
    width: 290px;
  }
}

.temp-7fb-main-container {
  width: 100%;
  padding: 32px 8px 0px;
  background-color: #212426;
  font-family: Roboto;
}

.temp-7fb-sub-heading {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.87;
  color: #fff;
  padding: 5px;
  text-align: center;
}
.temp-7fb-offer-section {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}
.temp-7fb-offers {
  padding-bottom: 26px;
  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #000;
  border-radius: 5px;
  position: relative;
  box-sizing: content-box;
  &:focus {
    outline: solid 10px yellow !important;
  }
}
.temp-13a-offers {
  @extend .temp-7fb-offers;
  margin-top: 16px;
}
.wrap-recommended {
  max-width: 30%;
  margin: 0px 12px;
}
.wrap-recommended-one {
  max-width: 50%;
}

.wrap-recommended-new-5a {
  max-width: 45%;
  margin: 0px 12px;
}
.temp-7fb-offer-header {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 6px 6px 0 rgb(0 0 0 / 31%);
  background-color: #fff;
  padding: 16px 8px 8px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #0164b1;
  font-size: 20px;
}
.temp-5a-offer-header {
  @extend .temp-7fb-offer-header;
  font-size: 15px;
  padding: 8px;
}

.temp-7fb-Polygon {
  color: #fff;
  font-size: 16px;
}
.temp-7fb-arrow {
  display: flex;
  justify-content: center;
  margin-top: -5px;
}
.temp-7fb-Recommendation {
  padding: 5px 10px 6px;
  border-radius: 3px;
  background-color: #b52c2c;
  font-size: 12px;
  color: white;
  width: 70%;
  margin: 0px auto;
  text-align: center;
  position: relative;
  top: 10px;
  z-index: 2;
}

.temp-recommendation-3cta-mobile {
  @extend .temp-7fb-Recommendation;
  margin: 0px 16px;
  width: 90%;
  font-size: 10px;
  padding: 2px;
}

.temp-7fb-Recommendation-new {
  @extend .temp-7fb-Recommendation;
  margin: 0px 35px;
}
.temp-7fb-rec-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-7fb-wrap-body {
  padding: 0px 16px 16px;
}
.temp-7fb-amt {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}
.temp-7fb-discounts {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
.temp-7fb-off {
  margin: 0 9px 0 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: #e3e3e3;
}
.temp-7fb-price {
  margin: 0 0 0 9px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: #696868;
  text-decoration: line-through;
}
.temp-7fb-offer-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 0px;
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: #fff;
}
.temp-7fb-coupon-wrapper {
  position: absolute;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.temp-7fb-coupon {
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #6e6e6e;
}
.temp-7fb-coupon-text {
  color: #adadad;
  text-decoration: underline;
  margin-left: 3px;
}
.temp-7fb-bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temp-15a-wrap-timer {
  margin: 0px 24px;
}
.temp-15a-subscribe-div {
  padding: 8px;
}

//new styles
.temp-5b-main-container-new {
  @extend .temp-5b-main-container;
  padding: 20px 120px;
  border-radius: 14px;
}

.temp-5b-offer-section-new {
  @extend .temp-5b-offer-section;
  padding: 10px 0px;
}

.temp-5b-wrap-body-new {
  @extend .temp-5b-wrap-body;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrap-recommended-new {
  @extend .wrap-recommended;
  min-width: 70%;
  max-width: none;
}

.temp-5fb-heading-new {
  @extend .temp-5fb-heading;
  color: #6e6e6e;
}

.temp-5fb-sub-heading-new {
  @extend .temp-5fb-sub-heading;
  font-family: PlayfairDisplay;
  font-weight: normal;
}

.temp-5b-wrap-offer-new {
  min-width: 40vw;
  width: fit-content;
}

.temp-5b-wrap-offer-2 {
  min-width: 45vw;
  width: 45vw;
}

.temp-2fb-btn-section-new {
  @extend .temp-2fb-btn-section;
  width: 30vw;
}

.temp-5b-wrap-offer-2 {
  min-width: 40vw;
  width: 40vw;
}

.conscent-balance-mobile-new {
  @extend .conscent-balance-mobile;
  margin-top: 16px;
  margin-bottom: 0px;
  color: #fff;
  max-width: fit-content;
  line-height: 1.87;
}

.mobile-pay-with-new {
  @extend .mobile-pay-with;
  margin: 0px;
  padding: 10px 2px 0px;
}

.temp-2fb-offers-section-new {
  @extend .temp-2fb-offers-section;
  background-color: inherit;
  border-radius: 0px;
  width: 60%;
}

.temp-5fb-passes-info-new {
  @extend .temp-5fb-passes-info;
  text-align: center;
  margin: 0px;
}

.temp-5fb-btn-section-new {
  @extend .temp-2fb-btn-section;
  width: 42vw;
}

.temp-2fb-sub-container1-new {
  @extend .temp-2fb-sub-container1;
  width: unset;
}

.temp-5fb-heading {
  @extend .temp-5fb-heading-new;
  background-color: inherit;
}

.temp-5b-main-container-two {
  @extend .temp-5b-main-container-new;
  background-color: #212426;
}

.temp-1fb-pricing-box-one-new {
  @extend .temp-1fb-pricing-box-one;
  background-color: #000;
}

.mobile-coupon-text-one {
  @extend .mobile-coupon-text;
  margin-top: 8px;
}

.temp-7fb-offers-new {
  @extend .temp-7fb-offers;
  padding-bottom: 0px;
}

.temp-5fb-main-container-one {
  @extend .temp-5b-main-container-new;
  padding: 20px 60px;
  background-color: #212426;
}

.temp-2fb-btn-section-one {
  @extend .temp-2fb-btn-section;
  width: unset;
}

.temp-2fb-offers-section-one {
  @extend .temp-2fb-offers-section;
  width: 90%;
}

.temp-5fb-sub-heading-one {
  @extend .temp-5fb-sub-heading;
  margin-top: revert;
}

.temp-2fb-sub-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212426;
  flex-direction: row;
}

//ends
.registration-fullpage-container {
  // width: 930px;
  // padding: 42px;
  // background-color: #212426;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 706px;

  background-color: #212426;
  font-weight: normal;
  color: #fff;
  text-align: center;
  :global(.ant-input) {
    color: #fff;
    &:hover {
      border-color: transparent;
      border-right-width: unset;
    }
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}
#registration-fullpage-child {
  @extend .registration-fullpage-container;
  max-width: 706px;
  width: 100%;
  padding: 42px;
  background-color: transparent;
  margin: auto;
}
.registration-heading {
  font-size: 30px;
  font-weight: bold;
}

.registration-subHeading {
  font-size: 18px;
}
.registration-child-container-mobile {
  @extend .registration-fullpage-container;
  width: 100%;
  padding: 16px;
  .registration-heading {
    font-size: 22px;
  }

  .registration-subHeading {
    font-size: 16px;
  }
}
.registration-input-wrap {
  margin-top: 24px !important;
}
.registration-input {
  height: 46px;
  background: #484848;
  border: 1px solid #707070;
  border-radius: 4px;
}
.registration-btn {
  width: 100%;
  height: 46px;
  background: #0164b1;
  box-shadow: 1px 3px 12px #00000029;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registration-google {
  @extend .registration-btn;
  background: #fff;
  font-weight: normal;
  color: #505050;
  margin-top: 28px;
  img {
    margin-right: 24px;
    width: 24px;
  }
}
.registration-vertical-line {
  border-right: none;
  border-bottom: 1.2px dashed #d3d3d3;
  position: relative;
}
.registration-or {
  width: 10%;
  height: 25px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  top: 12px;
  background: #212426;
  left: 45%;
}
