.main-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-inputs {
  width: 359px;
  height: 175px;
  margin: 28px 3.5px 12px 0.5px;
}

.login-text {
  font-size: 25px;
  font-weight: 500;
  color: #222;
  margin-left: 38.5px;
  margin-bottom: 25px;
}

.email-input-icon {
  margin-right: 20px;
  margin-bottom: 22px;
}

.password-input-icon {
  margin-right: 20px;
  margin-bottom: 10px;
}

.login-div {
  width: 436px;
  height: 460px;
  padding: 20px 35px 10px 38px;
  border-radius: 8px;
  box-shadow: 20px 10px 46px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
}

.main-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email-input {
  display: flex;
  align-items: flex-end;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 146px;
    height: 42px;
    object-fit: contain;
  }
}

.login-button {
  width: 363px;
  height: 45px;
  border-radius: 4px;
  border: solid 1px #292929;
  background-color: #292929;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 16px;
  margin-bottom: 75px;
}

.email-input {
  .email-input-field {
    width: 100%;
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      width: 100%;
      border-color: #1aac6a;
      position: relative;
      height: 40px;
      &::placeholder {
        color: #1aac6a;
        font-size: 10px;
        font-weight: 500;
        position: absolute;
        top: 0;
      }
    }
  }
}

.password-input {
  display: flex;
  align-items: center;
  .password-input-field {
    width: 100%;
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      width: 100%;
      border-bottom-width: 1px;
      border-color: #959595;
      &::placeholder {
        font-size: 12px;
        font-weight: 500;
        color: #292929;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: Roboto;
    font-size: 11px;
    color: #1d2943;
  }
}

.login-error {
  top: 360px;
  color: red;
  position: absolute;
  bottom: 32px;
  left: 148px;
}

.forgot-password {
  cursor: pointer;
}
