@import '../../styles/variables.module.scss';

.auth-page-row {
  min-height: 100vh;
  padding: 70px 15px 0;
  background-color: #f0f2f5;
}

.logo-icon {
  width: 65px;
  height: 60px;
  margin-bottom: 20px;
}

.terms-condition {
  display: flex;
  justify-content: flex-start;
  max-width: 600px;
  margin: auto;
  align-items: center;
  margin-bottom: 10px;

  p {
    color: #000;
    font-size: 13px;
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.background-white {
  background-color: #fff;
}

.background-blue {
  background-color: #f4f9fd;
}

.form-container {
  display: flex;
  font-family: Roboto, sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafafa;

  :global(.ant-form-item-explain),
  :global(.ant-form-item-explain-error) {
    text-align: left;
  }

  :global(.ant-input) {
    padding: 0;
    font-size: $input-font-size;

    &:focus {
      font-size: $input-font-size;
    }
  }

  :global(.ant-input-affix-wrapper) {
    &:focus {
      border-color: transparent !important;
      border: 1px solid #19b4c8;
    }
  }

  :global(.ant-btn-primary) {
    width: 157px;
    height: 46px;
    background: #14213d;
    box-shadow: 0 3px 6px #b1d6f8b3;
    border-radius: 0;
    border: none;
    font-size: $input-font-size;
    font-weight: bold;

    &:hover {
      background: #14213d;
    }

    &:focus {
      background: #14213d;
    }
  }

  :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }
}

.form-item-shadow {
  border: 1px solid #b1d6f8b3;
  height: 40px;
  background: #fff;
  border-radius: 5px;
}

.form-item {
  width: 100%;
  text-align: center;
  position: relative;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #14213d;
}

.button {
  height: 30px;
  width: 200px;
  background-color: #14213d;
  color: #fff;
}

.blue-link {
  margin: -8px 0;
  font-size: 14px;
  color: #19b4c8;
  cursor: pointer;
  text-align: left;
  position: absolute;
}

.resend-otp-text {
  position: absolute;
  margin-top: -8px;
  font-size: 12px;
}

.with-password {
  display: flex;
  justify-content: flex-start;
  margin-top: -8px;
  align-items: center;

  p {
    font-size: 10px;
    margin-bottom: -3px;
  }
}

.sing-up {
  @extend .with-password;

  margin: 12px auto;
  width: 177px;
}

.override-form-item {
  margin-bottom: 0 !important;
  margin-top: 100px !important;
}

.border-container {
  box-shadow: 0 3px 6px #b1d6f8b3;
  background-color: #fff;
  text-align: center;
  padding: 50px 20px 0 20px;
  max-width: 100%;
  width: 390px;
  margin: auto;
  border-radius: 10px;
  padding-bottom: 32px;
}

.border-box {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  text-align: center;
  padding: 44px 39px 0 39px;
  max-width: 100%;
  width: 366px;
  margin: auto;
  border-radius: 12px;
  padding-bottom: 32px;
}
.welcome-text {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}

@media (max-width: $breakpoint-sm) {
  .override-form-item {
    margin-top: 80px !important;
  }

  .border-container {
    width: 90%;
  }
  .border-box {
    width: 90%;
  }

  .welcome-text {
    font-size: 25px;
  }
}

@media (max-height: 450px) {
  .form-container {
    display: block;
    height: auto;
  }
}
