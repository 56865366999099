@import '../../styles/variables.module.scss';

.subscription-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    position: absolute;
    font-size: 16px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: #b10f15;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }

  input {
    font-size: 16px;
    outline: none;
    border: 1px solid #b10f15;
    border-radius: 5px;
    padding: 16px 20px;
    color: #000;
    transition: 0.1s ease-out;
    width: 100%;
    height: 46px;
  }

  input:focus {
    border-color: #b10f15;
    border: 2px solid #b10f15;
  }

  input:focus + label {
    color: #b10f15;
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
}

.input-field {
  position: relative;
  width: 100%;
}

.asterisk {
  color: #b10f15;
}

.otp-wrapper {
  display: inline-block;
  position: relative;
  span {
    font-weight: 500;
  }
  .invalid-otp {
    position: absolute;
    top: -20px;
    left: 26px;
    color: red;
  }
  .resend-otp {
    position: absolute;
    bottom: -20px;
    left: 26px;
    color: black;
    cursor: pointer;
  }
}

.resend-otp-text {
  margin-left: -12px;
  position: absolute;
  bottom: -20px;
  left: 26px;
  white-space: nowrap;
}
.otp-input {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: $breakpoint-sm) {
  .input-field {
    input {
      width: 100% !important;
    }
  }
  .otp-wrapper {
    margin-bottom: 20px;
    margin-top: 10px;
    input {
      width: 100px !important;
      margin-left: 0px !important;
    }
  }
}
