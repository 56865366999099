@import 'styles/variables.module.scss';

.main-container {
  padding: 12px 0px;
  box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  .sub-container {
    max-width: 700px;
    margin: auto;
    display: flex;
    align-items: center;
    text-align: center;

    .text,
    .divider {
      display: inline-block;
    }
    .text {
      width: 25%;
      font-size: 12px;
      cursor: pointer;
      color: #aaaaaa;
    }

    .text.active {
      color: #b10f15;
    }
    .divider {
      width: 12.5%;
      height: 1px;
      background-color: #e2e0e3;
    }
  }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .main-container {
    padding: 19px 5px;

    .sub-container {
      .text {
        font-size: 14px !important;
      }
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .main-container {
    padding: 19px 5px;

    .sub-container {
      .text {
        width: 16.5%;
        font-size: 16px;
      }

      .divider {
        width: 25%;
      }
    }
  }
}
