@import '../../styles/variables.module.scss';

.blue-button {
  background-color: $button-primary-color;
  color: white;
  outline: none;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 68px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  width: 100%;

  &:disabled {
    color: #bbb;
    background-color: #707070;
    cursor: not-allowed;
  }

  &.disabled-opacity {
    opacity: 0.7;
  }
}

.blue-button:hover {
  background: $button-primary-hover-background;
  box-shadow: $button-primary-hover-box-shadow;
  opacity: $button-primary-hover-box-opacity;
}

.blue-button:active:not(.disabled) {
  background-color: #19b4c8;
}
