.heading {
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: bold;
  color: #1d2226;
}

.sub-heading {
  font-family: 'Roboto';
  font-size: 15px;
  color: #1d2226;
  opacity: 0.6;
}

.terms-condition {
  display: flex;
  width: 240px;
  align-items: center;
  margin-bottom: 8px;
  font-family: Roboto;

  p {
    color: #000;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.inp-below-text {
  font-size: 11px;
  opacity: 0.6;
}
