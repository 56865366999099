@import 'styles/variables.module.scss';

.main-container {
  width: 992px;
  padding: 42px 11px;
  margin: 114px auto;
  border-radius: 2px;
  background-color: #f5faff;
  text-align: center;
  font-family: 'Roboto';
  color: #0d0f11;

  .main-header {
    font-size: 43px;
    margin: 0 0;
    font-weight: 600;
  }

  .sub-header {
    font-size: 18px;
    margin: 0;
  }

  .cover-img {
    margin: 35px 0;
    width: 282.6px;
    height: 136.7px;
    object-fit: contain;
  }

  .description-text {
    font-size: 19px;
    list-style: 1.63;
    color: #15323d;
    margin: 0 0 35px;

    .description-text-mobile {
      display: none;
      height: 10px;
    }
  }

  .continue-button {
    width: 253px;
    height: 46px;
    border-radius: 8px;
    background-color: #b10f15;
    color: white;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    border: none;
  }

  .offer-card {
    margin: 0 0 10px;
    padding: 18.5px 10.1px;
    font-size: 13px;
    display: flex;
    border-radius: 7px;
    border: solid 1px #d3d3d3;
    background-color: #fff;
    margin: 9px 0;

    .img {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      margin-right: 11.5px;
    }

    .text {
      margin-bottom: 0;
      font-size: 20px;
      color: #0d0f11;
      cursor: pointer;
    }
  }
}

.offer-container {
  width: 95%;
  margin: auto;
  margin-bottom: 40px;

  .offer-text {
    font-size: 20px;
    font-weight: bold;
  }
}

@media (min-width: '768px') {
  .offer-container {
    width: 387px;
  }
}

@media (max-width: $breakpoint-lg) {
  .main-container {
    width: auto;
  }
}

@media (max-width: $breakpoint-sm) {
  .main-container {
    .col-reverse {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    .main-header {
      font-size: 34px;
    }

    .sub-header {
      font-size: 14px;
    }

    .description-text {
      font-size: 16px;

      .description-text-mobile {
        display: block;
      }
    }
  }
}
