@import 'styles/variables.module.scss';

.container {
  width: 365px;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  text-align: center;
  font-weight: 500;
  padding-bottom: 1px;
  max-height: 99vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  :global(.ant-checkbox-wrapper-checked) {
    :global(.ant-checkbox-checked) {
      :global(.ant-checkbox-inner) {
        background-color: #b10f15;
      }
    }
  }

  :global(.ant-checkbox-wrapper) {
    :global(.ant-checkbox-inner) {
      border-color: #b10f15;
    }
  }

  :global(.ant-checkbox-checked::after) {
    border: 1px solid #b10f15;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    border-color: #b10f15;
  }
}

.header {
  position: fixed;
  width: 365px;
  background: #b10f15;
  padding: 14px 16px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  height: 64px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  z-index: 2;
}

.body-container {
  margin-top: 64px;
  z-index: 1;
}
.item-container {
  padding: 16px 16px 0px;
  text-align: left;
  height: 155px;
  position: relative;
}

.wrap-items {
  height: 82px;
  overflow: auto;
}
.view-more {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #b00000;
  margin-left: 22px;
  cursor: pointer;
}
.item-heading {
  position: relative;
  font-size: 17px;
  color: #1b1d1d;
}
.items {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 14px;
  color: #242424;
  line-height: 1.8;
  margin-left: 6px;
  font-weight: 400;
}
.item-bullet {
  width: 5px;
  height: 5px;
  border: 1px solid #c42001;
  border-radius: 30px;
  opacity: 1;
  margin-right: 10px;
}
.subscription-button {
  position: relative;
  width: 141px;
  height: 70px;
  color: #413d3d;
  font-size: 21px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-price {
  margin-top: 5px;
}

.duration-text {
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -5px;
  font-weight: bold;
  color: #666;
}

.check-box {
  font-size: 12px;
  font-weight: 700;
  width: 90%;
  text-align: center;
  position: absolute;
  bottom: -0.5px;
}

.button-container {
  width: 305px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 160px;
}

.wrap-offers-and-benefits {
  overflow: auto;
}

.nav-buttons {
  position: absolute;
  top: 108px;
  left: 0px;
  user-select: none;
}

.pre-button {
  font-size: 30px;
  z-index: 999;
}

.next-button {
  font-size: 30px;
  left: 332px;
  z-index: 999;
}

.loading-container {
  display: flex;
  justify-content: center;
  height: 420px;
  align-items: center;
}
.subscribe-button-wrap {
  position: relative;
  bottom: 4px;
}

@media (max-width: $breakpoint-xs) {
  .container {
    width: 98%;
    margin: auto;
  }

  .header {
    width: 98%;
    margin: auto;
  }
  .subscription-button {
    width: 134px;
    margin: 3px;
  }
  .next-button {
    left: 91%;
  }
}
